body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

antd-table-thead {
  background-color: red;
}

.body {
  width: auto;
}

@font-face {
  font-family: 'DIGITAL';
  src: url('assets/fonts/Digital7-rg1mL.ttf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'LCD';
  src: url('assets/fonts/LCD14.otf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'BARRBAR';
  src: url('assets/fonts/Barrbar-DOKOR.otf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'RONYSISWADI';
  src: url('assets/fonts/RonysiswadiArchitect6-X3V9G.ttf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'SPARKY';
  src: url('assets/fonts/SparkyStonesRegular-BW6ld.ttf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'SUNNY';
  src: url('assets/fonts/SunnyspellsRegular-MV9ze.otf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'DS_DIGI';
  src: url('assets/fonts/DS-DIGI.TTF') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'DS_DIGIB';
  src: url('assets/fonts/DS-DIGIB.TTF') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'Zing';
  src: url('assets/fonts/zing-rust-grunge2-base.otf') format('opentype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'BrandingSF';
  src: url('assets/fonts/BrandingSF-Cmp.ttf') format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'FUTURA_EXTRA_BLACK';
  src: url('assets/fonts/futura-extra-black-condensed-italic-bt.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'FUTURA_EXTRA_BLACK_OBLIQUE';
  src: url('assets/fonts/FuturaPTExtraBoldOblique.otf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Anton-Regular';
  src: url('assets/fonts/Anton-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'BebasNeue-Regular';
  src: url('assets/fonts/BebasNeue-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'BigShouldersDisplay';
  src: url('assets/fonts/BigShouldersDisplay-VariableFont_wght.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Staatliches-Regular';
  src: url('assets/fonts/Staatliches-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Teko-VariableFont_wght';
  src: url('assets/fonts/Teko-VariableFont_wght.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'AbrilFatface-Regular';
  src: url('assets/fonts/AbrilFatface-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'BaiJamjuree-Regular';
  src: url('assets/fonts/BaiJamjuree-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Barlow-Regular';
  src: url('assets/fonts/Barlow-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'JosefinSans-VariableFont_wght';
  src: url('assets/fonts/JosefinSans-VariableFont_wght.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'LilitaOne-Regular';
  src: url('assets/fonts/LilitaOne-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Lobster-Regular';
  src: url('assets/fonts/Lobster-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Oswald-VariableFont_wght';
  src: url('assets/fonts/Oswald-VariableFont_wght.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Pacifico-Regular';
  src: url('assets/fonts/Pacifico-Regular.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'SpaceGrotesk-VariableFont_wght';
  src: url('assets/fonts/SpaceGrotesk-VariableFont_wght.ttf') format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Shred-Handed';
  src: url('assets/fonts/Shred-Handed.otf') format('truetype');
  /* Outras propriedades da fonte */
}