body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

antd-table-thead {
  background-color: red;
}

.body {
  width: auto;
}

@font-face {
  font-family: 'DIGITAL';
  src: url(/static/media/Digital7-rg1mL.c924522e.ttf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'LCD';
  src: url(/static/media/LCD14.c52a6a7e.otf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'Roboto';
  src: url(/static/media/Roboto-Regular.5673da52.ttf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'BARRBAR';
  src: url(/static/media/Barrbar-DOKOR.5fb9306d.otf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'RONYSISWADI';
  src: url(/static/media/RonysiswadiArchitect6-X3V9G.00971fdd.ttf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'SPARKY';
  src: url(/static/media/SparkyStonesRegular-BW6ld.9a06f1b1.ttf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'SUNNY';
  src: url(/static/media/SunnyspellsRegular-MV9ze.fe02cfe0.otf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'DS_DIGI';
  src: url(/static/media/DS-DIGI.63f874d1.TTF) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'DS_DIGIB';
  src: url(/static/media/DS-DIGIB.1467e2d3.TTF) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'Zing';
  src: url(/static/media/zing-rust-grunge2-base.bf457a27.otf) format('opentype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'BrandingSF';
  src: url(/static/media/BrandingSF-Cmp.9b9ce8c5.ttf) format('truetype');
  /* Outras propriedades da fonte */
}

@font-face {
  font-family: 'FUTURA_EXTRA_BLACK';
  src: url(/static/media/futura-extra-black-condensed-italic-bt.a6bbd091.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'FUTURA_EXTRA_BLACK_OBLIQUE';
  src: url(/static/media/FuturaPTExtraBoldOblique.2f853cc0.otf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Anton-Regular';
  src: url(/static/media/Anton-Regular.055c4df4.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'BebasNeue-Regular';
  src: url(/static/media/BebasNeue-Regular.b2b29306.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'BigShouldersDisplay';
  src: url(/static/media/BigShouldersDisplay-VariableFont_wght.43c70be8.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Staatliches-Regular';
  src: url(/static/media/Staatliches-Regular.0b1946ea.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Teko-VariableFont_wght';
  src: url(/static/media/Teko-VariableFont_wght.4d23bd0f.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Poppins-Regular';
  src: url(/static/media/Poppins-Regular.093ee89b.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'AbrilFatface-Regular';
  src: url(/static/media/AbrilFatface-Regular.738419c3.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'BaiJamjuree-Regular';
  src: url(/static/media/BaiJamjuree-Regular.3d6481eb.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Barlow-Regular';
  src: url(/static/media/Barlow-Regular.9373fb66.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'JosefinSans-VariableFont_wght';
  src: url(/static/media/JosefinSans-VariableFont_wght.2e8222ac.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'LilitaOne-Regular';
  src: url(/static/media/LilitaOne-Regular.ce83b4bf.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Lobster-Regular';
  src: url(/static/media/Lobster-Regular.c3191f3b.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Oswald-VariableFont_wght';
  src: url(/static/media/Oswald-VariableFont_wght.033f2e78.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Pacifico-Regular';
  src: url(/static/media/Pacifico-Regular.85bb2d0e.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'SpaceGrotesk-VariableFont_wght';
  src: url(/static/media/SpaceGrotesk-VariableFont_wght.f8409c4c.ttf) format('truetype');
  /* Outras propriedades da fonte */
}
@font-face {
  font-family: 'Shred-Handed';
  src: url(/static/media/Shred-Handed.0f46a8bb.otf) format('truetype');
  /* Outras propriedades da fonte */
}
.center {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center; }

.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite; }

.break {
  flex-basis: 100%;
  height: 0;
  text-align: center; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

